<template>
	<div>
		<pui-datatable :modelName="modelName" :modelColumnDefs="modelColumnDefs" :actions="actions" :navigableDetail="false"></pui-datatable>
	</div>
</template>

<script>
import SubscriptionActions from './SubscriptionActions.js';

export default {
	name: 'subscription-grid',
	components: {},
	data() {
		return {
			modelName: 'subscription',
			actions: SubscriptionActions.gridactions,
			modelColumnDefs: {
				cancelled: {
					render: (data, type, row) => {
						return row.cancelled ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				delayeta: {
					render: (data, type, row) => {
						return row.delayeta ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				delayetc: {
					render: (data, type, row) => {
						return row.delayetc ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				visitaps: {
					render: (data, type, row) => {
						return row.visitaps ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				visitpof: {
					render: (data, type, row) => {
						return row.visitpof ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stoppba: {
					render: (data, type, row) => {
						return row.stoppba ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stopfla: {
					render: (data, type, row) => {
						return row.stopfla ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stopafa: {
					render: (data, type, row) => {
						return row.stopafa ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stopfcl: {
					render: (data, type, row) => {
						return row.stopafa ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stoplcl: {
					render: (data, type, row) => {
						return row.stopafa ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stoprts: {
					render: (data, type, row) => {
						return row.stopafa ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stoplla: {
					render: (data, type, row) => {
						return row.stopafa ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stopias: {
					render: (data, type, row) => {
						return row.stopafa ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stopeas: {
					render: (data, type, row) => {
						return row.stopafa ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stoppbd: {
					render: (data, type, row) => {
						return row.stopafa ? `<i class="fas fa-check state-check"></i>` : '';
					}
				}
			}
		};
	}
};
</script>
<style lang="css">
.state-check {
	color: #00bcd4;
	font-size: 1em;
}
</style>
